import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/Buttons";
import Input from "components/Input";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import { api } from "helpers/api/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const uploadSoloIcon = require("assets/images/upload-solo.svg");

export default function EmailModal({
  isOpen,
  handleModal,
}: {
  isOpen: boolean;
  handleModal: () => void;
}) {
  const [imgURL, setImgURL] = useState<string>("");
  const [tempImg, setTempImg] = useState<File>();
  const [imageUploading, setImageUploading] = useState(false);
  const { data, refetch, isLoading } = useQuery({
    queryKey: ["config"],
    queryFn: () => api.getConfig(),
  });

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageUploading(true);
    api
      .getImageSignedURL(e.target?.files?.[0]?.type)
      .then((res) => {
        axios
          .put(
            res.uploadUrl,
            // remove data:image/png;base64, from the base64 string
            //   (reader.result as string)?.split(";")[1]
            e.target?.files?.[0],
            { headers: { "Content-Type": e?.target?.files?.[0].type } }
          )
          .then(() => {
            setImgURL(res.accessUrl);
            // setTempImg so it can be shown in the UI
            setTempImg(e?.target?.files?.[0]);
            // refetch();
            setImageUploading(false);
          })
          .catch((err) => {
            // console.log(err);
            setImgURL("");
            setImageUploading(false);
          });
      })
      .catch((err) => {
        // console.log(err);
        setImageUploading(false);
      });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    api
      .updateEmailConfig({
        selectionEmailTemplateTitle: e.target[1].value,
        selectionEmailTemplateImgUrl: imgURL,
      })
      .then(() => {
        refetch();
        handleModal();
        toast.success("Email Config updated successfully");
      })
      .catch((err) => {
        toast.error("Error updating Email Config");
      });
  };

  useEffect(() => {
    if (data?.selectionEmailTemplateImgUrl) {
      setImgURL(data?.selectionEmailTemplateImgUrl);
    }
  }, [data?.selectionEmailTemplateImgUrl]);

  return (
    <Modal isOpen={isOpen} handleModal={handleModal} title="Email Config">
      {isLoading && <Spinner />}
      {!isLoading && (
        <form className="grid gap-4" onSubmit={handleSubmit}>
          <label htmlFor="profileImg" className="grid gap-3 w-full">
            <span>Upload image for Email Template</span>
            <div className="flex items-center gap-[0.75rem]">
              <input
                type="file"
                onChange={handleUpload}
                id="profileImg"
                className="hidden"
                accept="image/png, image/jpeg"
              />
              {imgURL ? (
                <div className="w-full h-[200px] rounded-[8px]">
                  <img
                    src={imgURL}
                    alt="team"
                    className="w-full h-full object-cover rounded-[8px]"
                  />
                </div>
              ) : (
                <div className="relative w-[100px] h-[100px] rounded-full border border-[#59DC3D]">
                  <img
                    src={uploadSoloIcon.default}
                    alt="upload"
                    className="cursor-pointer w-[2rem] h-[2rem] rounded-full object-contain absolute"
                    style={{
                      // center the icon
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                  {imageUploading && (
                    <div className="absolute w-full h-full bg-[black]/50 rounded-full">
                      <Spinner />
                    </div>
                  )}
                </div>
              )}
            </div>
          </label>
          <Input
            label="Email Text"
            placeholder="Enter Email Text"
            defaultValue={data.selectionEmailTemplateTitle}
          />
          <Button type="submit" className="w-full">
            Save
          </Button>
        </form>
      )}
    </Modal>
  );
}
